/*
 * CSS
 */
html,
body,
#root {
    height: 100%;
}

* {
    -webkit-tap-highlight-color: transparent !important;
}

::-webkit-scrollbar {
    width: 0;
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: var(--chakra-colors-gray-50);
    border-radius: 30px;
}

::-webkit-scrollbar-thumb:hover {
    background: var(--chakra-colors-gray-100);
}

.base {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: inherit;
}

.filter {
    &.searchInput {
        height: 40px;
        border-radius: 10px;
        width: 100%;

        &.unsetWidth {
            width: unset;
        }
    }
}

.dropDownMenu {
    color: rgba(22, 22, 22, 0.5);
    font-family: DM Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.26px;
    border-radius: 24px !important;
    box-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.18) !important;
}

.toaster {
    position: absolute;
}

#nprogress .bar {
    background: rgba(117, 81, 255, 1) !important;
}

#nprogress .peg {
    box-shadow:
        0 0 10px rgba(117, 81, 255, 1),
        0 0 5px rgba(117, 81, 255, 1);
}

.paginationPageSizeSelect {
    font-size: 18px;
    color: var(--chakra-colors-gray-600);

    & > .paginationPageSizeSelectSeparator,
    & .paginationPageSizeSelectTotalCount {
        color: var(--chakra-colors-gray-500);
        padding-left: 5px;
        padding-right: 5px;
    }

    & > div {
        width: unset !important;

        & > .chakra-form-control label {
            margin: 0;
        }
    }

    & .chakra-select__wrapper .chakra-select {
        padding: 0;
        border: none;
        text-align: center;
        height: unset !important;
        font-family: unset !important;
        font-weight: unset !important;
        font-size: unset !important;
        line-height: unset !important;
        height: unset !important;
        border-radius: unset !important;
        border-width: unset !important;
        border-color: unset !important;
        background-color: transparent !important;
        color: var(--chakra-colors-gray-500) !important;

        &:focus,
        &:hover,
        &:active {
            border: none;
        }
    }

    & .chakra-select__icon-wrapper {
        display: none;
    }
}

.chakra-table__container {
    position: relative;
    overflow: auto !important;
    display: unset !important;
}

.chakra-tabs__tablist {
    position: relative;
}

.toaster > div {
    left: 20px !important;
}

.rti--container {
    width: 100%;

    & .rti--input {
        width: 100%;
    }
}

.notify {
    background-color: tomato;
    position: absolute;
    width: 100%;
    padding: 6px;
    top: 0;
    right: 0;
    z-index: 10000;
    text-align: center;
    font-size: 17px;
    color: white;
}
