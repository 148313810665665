/*
CSS
*/
.sendMessage {
    height: 100%;
    width: 100%;

    .highlightHeadline {
        background: linear-gradient(
            23deg,
            var(--chakra-colors-brand-500) 0%,
            var(--chakra-colors-purple-500) 99.8%
        );
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    select.react-time-picker__inputGroup__input.react-time-picker__inputGroup__amPm,
    input.react-time-picker__inputGroup__input.react-time-picker__inputGroup__second,
    input.react-time-picker__inputGroup__input.react-time-picker__inputGroup__minute,
    input.react-time-picker__inputGroup__input.react-time-picker__inputGroup__hour {
        height: unset !important;
        background-color: transparent;
        font-weight: 500;
    }

    select.react-time-picker__inputGroup__input.react-time-picker__inputGroup__amPm {
        color: var(--chakra-colors-brand-500);
    }

    .repeatDeliveryAt {
        height: 28px !important;
        width: max-content !important;
        font-weight: 500 !important;
        padding-left: 10px !important;
    }

    .react-time-picker__wrapper {
        background-color: var(--chakra-colors-brand-100);
        color: var(--chakra-colors-brand-500);
        border: none;
        padding: 2px 12px;
        border-radius: 22px;
        font-weight: 500;
        font-family: Poppins;
        display: flex;
        gap: 6px;
        align-items: center;

        & ::placeholder {
            color: var(--chakra-colors-brand-500);
        }

        .react-time-picker__inputGroup {
            min-width: unset;
        }

        .react-time-picker__inputGroup__input
            react-time-picker__inputGroup__hour {
            background-color: var(--chakra-colors-brand-100);
            padding: 6px;
        }

        .react-time-picker__inputGroup__input:invalid {
            background-color: var(--chakra-colors-brand-100);
            color: var(--chakra-colors-brand-500);

            &::placeholder {
                color: var(--chakra-colors-brand-500);
            }
        }

        .react-time-picker__clock-button.react-time-picker__button,
        .react-time-picker__clear-button.react-time-picker__button {
            & svg {
                stroke: var(--chakra-colors-brand-500);
            }
        }
    }
}
